<template>
  <v-container class="tw-pt-0 tw-mt-0">
    <div class="auth-container tw-mt-10 tw-min-h-screen">
      <StepsComponent :step="1" />
      <div class="tw-relative tw-min-h-screen">
        <TitleComponent class="tw-mt-8" text="Créez votre compte" />
        <SignupSecondaryTextComponent class="public-text-register tw-mt-4">
          Voici les informations qui ont été renseignées et celles concernant votre entreprise.<br />
          Nous vous invitons à indiquer un login et mot de passe pour accéder à la plateforme.
        </SignupSecondaryTextComponent>
        <v-form ref="form" @submit="onSubmit" v-model="formValid" class="tw-mt-8" autocomplete="off">
          <div class="grid-step1">
            <TextFieldComponent :rules="rules.firstName" :error-messages="errors.firstName" v-model="form.firstName"
              label="Prénom" />
            <TextFieldComponent :rules="rules.lastName" v-model="form.lastName" :error-messages="errors.lastName"
              label="Nom" />
            <TextFieldComponent :rules="rules.email" v-model="form.email" label="Adresse e-mail" />
            <TextFieldComponent :rules="rules.socialReason" v-model="form.socialReason"
              :error-messages="errors.socialReason" label="Raison social de l'entreprise" />
            <TextFieldComponent :rules="rules.service" v-model="form.service" label="Service" />
            <TextFieldComponent :rules="rules.phone" v-model="form.phone" :error-messages="errors.phone"
              messages="Ce numéro vous servira lors de la double authentification" label="Numéro de téléphone"
              textRules="" autocomplete="new-password" />
            <PasswordFieldComponent @keyup="handelPassword" v-model="form.password" :rules="rules.password"
              :error-messages="errors.password" label="Mot de passe" validate passwordRules="Au moins 12 caractères, dont 1 majuscule, 1 chiffre, 1 minuscule, 1 caractère
spécial (., @, !, _, %, #, :, $, *, ..., -)" />
            <PasswordFieldComponent :disabled="disableComfirmPassword" :rules="rules.confirmPassword"
              v-model="form.confirmPassword" :error-messages="errors.confirmPassword"
              label="Vérification de mot de passe" />
          </div>
          <div class="tw-flex tw-justify-center tw-pb-20 tw-mt-6">
            <PrimaryButtonComponent :style="{
            backgroundColor: `${referer.theme.ctaBgColor} !important`,
            color: `${referer.theme.ctaTextColor} !important` }" :loading="isSendingForm"
              :disabled="!formValid || isSendingForm" type="submit">Créer</PrimaryButtonComponent>
          </div>
        </v-form>
        <footer-paragraph-text />
      </div>
    </div>
  </v-container>
</template>

<script>
import TitleComponent from "@/components/common/TitleComponent";
import SignupSecondaryTextComponent from "@/features/auth/components/SignupSecondaryTextComponent";
import PrimaryButtonComponent from "@/components/common/PrimaryButtonComponent";
import StepsComponent from "@/features/auth/components/StepsComponent";
import { validationRules } from "@/utils/validation-rules";
import { CollaboratorFirstStepSignUpApi, GetCollaboratorFirstStepSignUpApi } from "@/features/auth/services/appApi";
export default {
  name: "FirstStepRegistrationPage",
  computed: {
    referer() {
      return this.$store.state.referrer;
    },
  },
  components: {
    StepsComponent,
    PrimaryButtonComponent,
    SignupSecondaryTextComponent,
    TitleComponent,
  },
  data() {
    return {
      formSent: false,
      formValid: false,
      isSendingForm: false,
      disableComfirmPassword: true,
      errors: {
        firstName: "",
        lastName: "",
        email: "",
        socialReason: "",
        service: "",
        phone: "",
        password: "",
        confirmPassword: "",
      },
      form: {
        firstName: "",
        lastName: "",
        email: "",
        socialReason: "",
        service: "",
        phone: "",
        password: "",
        confirmPassword: "",
      },
      rules: {
        firstName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "firstName"),
        ],
        lastName: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "lastName"),
        ],
        gender: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "gender"),
        ],
        email: [
          validationRules.required,
          validationRules.email,
          () => validationRules.asyncError(this.errors, "email"),
        ],
        socialReason: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "socialReason"),
        ],
        service: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "service"),
        ],
        phone: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "phone"),
        ],
        password: [
          validationRules.required,
          () => validationRules.asyncError(this.errors, "password"),
        ],
        confirmPassword: [
          validationRules.required,
          (value) => validationRules.passwordMatch(value, this.form.password),
        ],
      },
    };
  },
  mounted() {
    GetCollaboratorFirstStepSignUpApi(this.$route.params.token).then((res) => {
      this.form.firstName = res.data.data.firstName;
      this.form.lastName = res.data.data.lastName;
      this.form.service = res.data.data.service;
      this.form.phone = res.data.data.phone;
      this.form.email = res.data.data.email;
      this.form.socialReason = res.data.data.workInCompany.socialReason;
    });
  },
  methods: {
    onSubmit($event) {
      $event.preventDefault();
      this.isSendingForm = true;
      CollaboratorFirstStepSignUpApi(this.$route.params.token, this.form)
        .then(() => {
          this.isSendingForm = false;
          this.formSent = true;
          this.$snackbar.showAlertMessage({
            message: "Votre compte a été bien modifiez",
            type: "success",
          });
          this.$router.push({
            name: "collaborator.signup.secondStep",
            params: { token: this.$route.params.token },
          });
        })
        .catch(({ response }) => {
          window.scrollTo(0, 0);
          this.isSendingForm = false;
          if (response.status === 400) {
            response.data.message.forEach((item) => {
              this.$notify({
                group: "foo",
                type: "error",
                title: "Attention",
                position: "bottom right",
                text: item,
              });
            });
          } else {
            this.$snackbar.showAlertMessage({
              message: "Erreur: la requête a échoué",
              type: "error",
            });
          }
          this.$refs.form.validate();
        });
    },
    handelPassword(event) {
      if (
        event.target.value.length >= 12 &&
        event.target.value.match(/[a-z]/) &&
        event.target.value.match(/[A-Z]/) &&
        event.target.value.match(/\d+/) &&
        (event.target.value.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/) || event.target.value.includes('-'))
      ) {
        this.disableComfirmPassword = false;
      } else {
        this.form.confirmPassword = "";
        this.disableComfirmPassword = true;
      }
    }
  },
};
</script>

<style scoped>

</style>
